@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;800&display=swap');

html {
	height: 100vh;
}

body {
	min-height: 100vh;
	font-size: 14px;
	font-family: 'Nunito Sans', 'Source Sans Pro', sans-serif;
	font-weight: 400;
}

@import "backgrounds.scss";
@import "buttons.scss";
@import "cards.scss";
@import "modal.scss";
@import "images.scss";
@import "navbarcomponent.scss";

//elements
@import "elements/search.scss";
@import "elements/cookieconsent.scss";
@import "elements/imagemanager.scss";
@import "elements/footer.scss";


//pages
@import "pages/signin.scss";
@import "pages/user.scss";
@import "pages/mainpage.scss";
@import "pages/chat.scss";
@import "pages/netzwerk.scss";
@import "pages/companies.scss";
@import "pages/companiespublic.scss";
@import "pages/newcompany.scss";
@import "pages/jitsipage.scss";
@import "pages/feedback.scss";
@import "pages/profile.scss";
@import "pages/currentcompany.scss";
@import "pages/aboutus.scss";
@import "pages/invitationpage.scss";
@import "pages/landingpage.scss";
@import "pages/crosspage.scss";

.photo {
  height: 70px;
  width: 70px;
}

.photo-feedback{
	height: 110px;
  width: 110px;
}

.copyright-logo {
	height: 50px;
	width: 50px;
}

.footer-menu {
	a {
		font-size: 10px;
		white-space: nowrap;
		margin-left: 20px;
		margin-right: 20px;
	}
	#landingpageleft {
		margin-left: 10px;
		margin-right: 5px;
	}
	#landingpageright {
		margin-left: 15px;
		margin-right: 10px;
	}
}

.fa-2_5x{
  font-size: 1.6em;
}

.icon-color {
    color: #868a8e;
}
.row-padding {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
}

.align-me {
  display: flex;
  align-items: center;
}


.center-col {
  max-height: 900px;
}

.avatar-br{
  box-shadow: 0 0 0 0.3rem #fff;
}

.date-large::before{
  font-size: 1rem
}

.message .message-content {
  padding: 0.4rem 0.4rem;
	color: #ffffff;
}

.message .avatar img {
  box-shadow: 0 0 0 0.15rem #fff;
}

.my-modal {
    width: 35vw;
    max-width: 35vw;
}


.main-layout .sidebar{
  height: calc(100vh - 9.6rem);

}

.main-layout .main{
  height: calc(100vh - 9.6rem);

}

#chatContactsList {
  scrollbar-width: auto;
}
.dropdown-toggle {
  min-width: 90px;
}



.mdb-lightbox [class*="col-"] {
    padding: .1rem;
}

.input-group-text{
  cursor: pointer;
  font-size: 0.8rem;
}



.underlined {
  text-decoration-line: underline;
}

.mdb-lightbox .lbox-hidden img:not(.zoom) {
	display: none;
}

#newscard .card-body {
	height: 274px;
}
.newsfeed-card {
	height: calc(100vh - 26.8rem);
}



.tab-content-chat {
    height: 100% !important;
}
.read-less{
	max-height: 43px;
	overflow: hidden;
}

.select-dropdown li img {
    float: right;
    width: 180px;
    height: 180px;
    margin: .3rem .75rem;
		border-radius: 0% !important;
}

/*.file-path-wrapper {
    visibility: hidden;
	}
*/
	.map-container {
height: 400px;
}

.transform-emoji{
	position: absolute;
	will-change: transform;
	top: 0px;
	left: -1050px;
	transform: translate3d(-5px, -438px, 0px);
}

.mitteilungen-card {
	height: calc(100vh - 6.4rem);
}

.companywrapper {
	position:fixed;
	left:0;
	top: 105px;
	width: 280px;
	max-height: calc(100vh - 6.4rem);
}

.chatwrapper {
	position:fixed;
	right:0;
	bottom: 0;
	width: 280px;
	max-height: calc(100vh - 6.4rem);
	z-index: 10;
}

.chatwrapper #chatContactsList {
	height: calc(100vh - 10.4rem);
}

.feedbackwrapper {
	position:fixed;
	right:-54px;
	top: 190px;
	.feedback {
		height: 38px;
		width: 138px;
		border-radius: 15px 15px 0 0;
		background-color: #03ECAB;
		transform: rotate(-90deg);
		text-align: center;
		padding: 8px;
		cursor: pointer;
		span {
			color: #ffffff;
			font-family: "Source Sans Pro";
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 22px;
		}
		img {
			width: 21px;
			height: 21px;
			margin-top: -5px;
			transform: rotate(90deg);
		}
	}
}

.feedbackwrapperCompany {
	position:absolute;
	right:15px;
	top: 315px;
	.feedback {
		height: 38px;
		width: 138px;
		border-radius: 15px 15px 15px 15px;
		background-color: #ABB0AF;
		//transform: rotate(-90deg);
		text-align: center;
		padding: 8px;
		cursor: pointer;
		span {
			color: #ffffff;
			font-family: "Source Sans Pro";
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 22px;
		}
		img {
			width: 21px;
			height: 21px;
			margin-top: -5px;
			transform: rotate(90deg);
		}
	}
}

.feedbackwrapperInterB2B {
	position:absolute;
	right:15px;
	top: -55px;
	.feedback {
		height: 38px;
		width: 138px;
		border-radius: 15px 15px 15px 15px;
		background-color: #ABB0AF;
		//transform: rotate(-90deg);
		text-align: center;
		padding: 8px;
		cursor: pointer;
		span {
			color: #ffffff;
			font-family: "Source Sans Pro";
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 22px;
		}
		img {
			width: 21px;
			height: 21px;
			margin-top: -5px;
			transform: rotate(90deg);
		}
	}
}

.resetCompany{
	width: 45px;
	padding: 0;
	position: absolute !important;
	top: 125px;
	right: 8px;
}

.md-form label{
	font-size: 0.75rem;
}

.btn-group-left {
	padding-right: 12rem;
	padding-bottom: 0.1rem;
	padding-top: 0.1rem;
}

.btn-group-right {
	padding-left: 12rem;
	padding-bottom: 0.1rem;
	padding-top: 0.1rem;
}

.imagepercent-status{
	padding-left: 0;
	padding-right: 3px;
}

.videopercent-status{
	padding-left: 6.7px;
	padding-right: 0;
}


	#basicCollapse2.collapse {
	  &:not(.show) {
	    display: none;
	  }
	}

	#basicCollapse2.collapsing {
	  overflow: hidden;
	  transition: height 3.35s ease ;
	}

	#basicCollapse2.fade {
	  transition: opacity 5.35s linear;

	  &:not(.show) {
	    opacity: 0;
	  }
	}

	.fullPageCollapse.collapse {
		&:not(.show) {
			display: none;
		}
	}

	.fullPageCollapse.collapsing {
		overflow: hidden;
		transition: height 2s ease;
	}

	.fullPageCollapse.fade {
		transition: opacity 2s linear;

		&:not(.show) {
			opacity: 0;
		}
	}

	.pageTopCollapse.collapse {
		&:not(.show) {
			display: none;
		}
	}

	.pageTopCollapse.collapsing {
		overflow: hidden;
		transition: height .9s ease;
	}

	.pageTopCollapse.fade {
		transition: opacity 2s linear;

		&:not(.show) {
			opacity: 0;
		}
	}

	.emoji-icon {
		margin-top: -0.27rem;
		position: absolute;
	}

	.badge-rounded {
	  border-radius: 6.25rem;
	}

	.history-text {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 10px;
}

.md-form > input[type]:autofill:not(.browser-default):not([type="search"]) + label {
//	transform: none;
//	-webkit-transform: none;
  transform: translateY(5px) scale(1);
	-webkit-transform: translateY(5px) scale(1);
	opacity: 0.51;
	color: #30445B;
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 13px;
	padding-left: 16px;
}

.md-form > input[type]:autofill:not(.browser-default):not([type="search"]) {
	filter:none;
	background-color: #f2f2f2;
}


.picker .picker__box {
    border-radius: 20px 20px 20px 20px;
		min-width: 23rem;
		margin-left: -2.3rem;
	}

.privacy-policy {
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 14px;
  text-align: center;
}

.adverts-text {
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 12px;
}

.jitsi-modal-title{
  color: #0360EC;
  font-family: "Source Sans Pro";
  font-size: 14px;
}

.userpage-title{
	height: 95px;
	width: 270px;
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	text-align: center;
}

.userpage-title-andern{
	height: 30px;
	width: 444px;
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	text-align: left;
}

.userpage-text {
  width: 280px;
	height: 55px;
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 12px;
	text-align: center;
}

.userpage-text-andern {
	height: 57px;
	width: 460px;
	color: #828282;
	font-family: "Source Sans Pro";
	font-size: 14px;
	letter-spacing: 0;
	line-height: 22px;
}

.userpage-btn-col {
	padding-top: 0px;
}

.unternehmen-text {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 14px;
}



#jitsi1.form-control {
	background-color: #F2F2F2;
	border-radius: 9px;
	border: none;
	padding-left: 10px;
}

#startTime.form-control {
	background-color: #F2F2F2;
	border-radius: 9px;
	border: none;
	width: 60%;
	text-align: center;
}

div.md-form:nth-child(4) {
	position: relative;
	margin-top: 0.8rem;
	margin-bottom: 1.5rem;
}
div.row:nth-child(5) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > div:nth-child(1){
	position: relative;
	margin-top: 0rem;
	margin-bottom: 0rem;
}
div.row:nth-child(5) > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div:nth-child(1){
	position: relative;
	margin-top: 00rem;
	margin-bottom: 0rem;
}

.datumformat{
	background-color: #F2F2F2;
	border-radius: 9px;
	border: none;
	text-align: center;
	padding-top: 11px;
	height: 40px;
}

@media (max-width: 575.98px) {
	.footer-menu {
		#landingpageleft {
			margin-left: 0px;
			margin-right: 0px;
		}
		#landingpageright {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	.companywrapper {
		top: 75px;
	}
	.feedbackwrapper {
		right:-45px;
	}
	.feedbackwrapperCompany {
		right:15px;
		top:150px;
		position:absolute !important;
	}
	.resetCompany{
		top: 5px;
		right: 10px;
	}
	.btn-group-left {
		padding-right: 3rem;
	}

	.btn-group-right {
		padding-left: 3rem;
	}

	.imagepercent-status{
		padding-left: 0;
		padding-right: 0.5px;
	}

	.videopercent-status{
		padding-left: 1px;
		padding-right: 0;
	}

	.userpage-btn-col {
		padding-top: 13px;
	}
	.my-modal {
	    width: 96vw;
	    max-width: 96vw;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.companywrapper {
		top: 75px;
	}
	.feedbackwrapper {
		right:-42px;
	}
	.feedbackwrapperCompany {
		right:15px;
		top:200px;
		position:absolute;
	}
	.resetCompany{
		top: 20px;
		right: 3px;
	}

	.my-modal {
	    width: 80vw;
	    max-width: 80vw;
	}

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.companywrapper {
		top: 83px;
	}
	.feedbackwrapper {
		right:-42px;
	}
	.feedbackwrapperCompany {
		right:370px;
		top:265px;
		position:absolute;
	}
	.resetCompany{
		top: 45px;
		right: 10px;
	}
	.my-modal {
	    width: 80vw;
	    max-width: 80vw;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.companywrapper {
		top: 83px;
	}
	.feedbackwrapperCompany {
		right:520px;
		top:305px;
		position:absolute;
	}
	.resetCompany{
		top: 65px;
		right: 10px;
	}
	.my-modal {
	    width: 80vw;
	    max-width: 80vw;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
