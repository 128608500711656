.card {
	box-shadow: none;
	.card-body {
		.list-group {
			.list-group-item {
				padding: .75rem;
				border-left:0;
				border-right:0;
			}
		}
	}
}

.userprofile-card {
  height: 85px;
  width: 160px;
  border-radius: 20px;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 10px;
  background-color: #FFFFFF;
}


.interb2b-card {
	border-radius: 20px;
	margin-bottom: 1rem;
	.card-title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ccc;
		color: #0360EC;
		text-align: center;
		a {
			color: #000;
		}
	}
	.card-body {
		padding:0;
		padding-left: 1rem;
		padding-right: 1rem;
		.list-group {
			.list-group-item {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				color: #000;
			}
		}
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.rating-card {
	border-radius: 20px;
	margin-bottom: 1rem;
	box-shadow: 13px 12px 13px 4px rgba(16, 27, 79, 0.2);
	.card-title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ccc;
		color: #0360EC;
		text-align: center;
		a {
			color: #000;
		}
	}
	.card-body {
		padding:0;
		padding-left: 1rem;
		padding-right: 1rem;
		.list-group {
			.list-group-item {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				color: #000;
			}
		}
	}
	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.currentCompany-card {
	border-radius: 10px;
	padding: 10px;
	background-color: #99999b;
	width: 180px;
}

.currentCompany-card-with-shadow {
	border-radius: 10px;
	padding: 10px;
	background-color: #99999b;
	width: 180px;
	box-shadow: 0 6px 8px 0 rgba(16, 27, 79, 0.2);
}

.card-adverts{
	height: 280px;
	padding-top: 55px !important;
}
@media (max-width: 575.98px) {
	.card-adverts{
		height: 15vh;
		padding-top: 10px !important;
	}
	.userprofile-card {
		height: 75px;
		width: 120px;
		border-radius: 10px;
		padding-top: 15px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.special-card {
  background-color: rgba(94, 85, 80, 1);
  opacity: .1;
}

.sticky-card {
	position: sticky;
	top: 8.2rem;
}

.rating-card {
	max-width: 325px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 575.98px) {
	.sticky-card {
		position: relative;
		top: 0;
	}
}



div[data-popper="popper"]{
	z-index: 100;
}

.transparent-card {
	box-shadow: none;
	background-color: transparent;
	//max-height: 13.5rem;
}
.netzwerk-card {
	.card-header:first-child {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}
}


#newsfeedcard {
	h4 {
		font-size: 1rem;
	}

	address, p {
		font-size: .6rem;
	}

	.photo {
		height: 70px;
		width: 70px;
	}

	.col, .col-md-auto {
		padding-right: 10px;
		padding-left: 10px;
	}
}

.chatlist-card {
	height: calc(100vh - 14.2rem);
	border-radius: 20px;
	margin-bottom: 1rem;
	padding-bottom: 15px;
	.card-title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 1rem;
		margin-right: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #ccc;
		color: #0360EC;
		text-align: center;
		a {
			color: #000;
		}
	}
	.card-body {
		padding:0;
		padding-left: 1rem;
		padding-right: 1rem;
		overflow: hidden;
		.scrollbar {
			height: calc(100vh - 30.5rem);
		}
		.list-group {
			max-width: 298px;
			.list-group-item {
				padding: .75rem 0;
				border-left:0;
				border-right:0;
				border-top:0;
				border-bottom:0;
				color: #000;
				.chat-name {
					color: #101B4F;
					font-size: 16px;
				}
				.chat-name-white {
					color: #ffff;
				}
				p {
					color: #9B9B9B;
					margin-bottom: 0;
				}
				span{
					font-size: 12px;
					color: #9B9B9B;
				}
			}

		}
	}

	.card-footer {
		background-color: #ffffff;
		border-top: 0;
		border-radius: .5rem;
	}
}

.card-height{
	height: calc(100vh - 9.14rem);
	.card-body {
		.scrollbar {
			height: calc(100vh - 25.5rem);
		}
	}
}

div {
  position: relative;
}

div.bottom::before{
  content : "";
  position: absolute;
  left    : 18px;
  bottom  : 0px;
  width   : 88%;
  border-bottom:1px solid #D6D6DB;
}

.dropdown-menu-chat{
	border-radius: 12px;
	width: 11.5rem;
	box-shadow: 0 6px 8px 0 rgba(16,27,79,0.2);
}

.chat-card-drop-right{
  position: absolute;
  will-change: transform;
  top: -45px;
  left: 200px;
  transform: translate3d(-156px, 2.54999px, 0px);
  width: 11.5rem;
}

.chat-card-drop-left{
  position: absolute;
  will-change: transform;
  top: -50px;
  left: -35px;
  transform: translate3d(-156px, 2.54999px, 0px);
  width: 11.5rem;
}

.interb2b-text-title{
	font-family: "Source Sans Pro";
	font-weight: bold;
	font-size: 12px;
}

@media (max-width: 575.98px) {
	.chatlist-card {
		height: calc(100vh - 5.2rem);
		.card-body {
			.scrollbar {
				height: calc(100vh - 21rem);
			}
			.list-group {
				max-width: 100%;
			}
		}
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.chatlist-card {
		.card-body {
			.list-group {
				max-width: 100%;
			}
		}
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.rating-card {
		max-width: 464px;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
