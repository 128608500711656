.chat-header {
	position: fixed;
	background: #ffffff;
	height: 80px;
	width: 100%;
	z-index: 999;
	top: 112px;
	padding-top:20px;
	padding-bottom: 20px;
	.container {
		height: 40px;
		.chat-header-left {
			height: 3.5rem;
			.avatar {
				min-width: 40px;
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}
			h5 {
				margin-top: 5px;
				margin-bottom: 0px;
				color: #101B4F;
				font-size: 18px;
			}

			.icon{
				height: 16px;
				margin-top: 10px;
				margin-left: 10px;
			}

			.chat-status-text {
				color: #9b9b9b;
				font-size: 10px;
			}

			.chat-status {
				box-sizing: border-box;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				display: inline-block;
				margin-right: 5px;
			}

			.status-online {
				background-color: #62EC03;
			}
			.status-offline {
				background-color: #6C757D;
			}

			.md-form {
				margin-top: 0px;
				margin-bottom: -4px;
				margin-left: -20px;
				label {
					opacity: 0.5;
					color: #101B4F;
					font-size: 14px;
					margin-top: -6px;
					padding-left: 17px;
				}
			}
			.chat-rename-icon {
				padding-left: 3px;
				height: 20px;
				width: 24px;
				margin-right: 10px;
				cursor: pointer;
				margin-top: -16px;
			}
		}
		.chat-header-left-rename {
			height: 3.5rem;
			.avatar {
				min-width: 40px;
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}
			h5 {
				margin-top: 5px;
				margin-bottom: 0px;
				color: #101B4F;
				font-size: 18px;
			}

			.icon{
				height: 16px;
				margin-top: 10px;
				margin-left: 10px;
			}

			.chat-status-text {
				color: #9b9b9b;
				font-size: 10px;
			}

			.chat-status {
				box-sizing: border-box;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				display: inline-block;
				margin-right: 5px;
			}

			.status-online {
				background-color: #62EC03;
			}
			.status-offline {
				background-color: #6C757D;
			}

			.md-form {
				margin-top: 4px;
				margin-bottom: -4px;
				margin-left: -20px;
				label {
					opacity: 0.5;
					color: #101B4F;
					font-size: 14px;
					margin-top: -6px;
					padding-left: 17px;
				}
			}
			.chat-rename-icon {
				padding-left: 3px;
				height: 20px;
				width: 24px;
				margin-right: 10px;
				cursor: pointer;
				margin-top: -16px;
			}
		}
		.chat-header-right {
			border-left: 1px solid #edeef1;
			height: 3.5rem;
			.chat-header-atitle {
				font-size: 10px;
				color: #9B9B9B;
				text-transform: uppercase;
				line-height: 14px;
				padding-top: 0;
				margin-bottom: 0;
			}
			.chat-header-acontent {
				padding-top: 0;
				margin-bottom: 0;
			}

			.md-form {
				margin-top: 0px;
				margin-bottom: 0px;
				label {
					opacity: 0.5;
					color: #101B4F;
					font-size: 16px;
					img {
						width: 21px;
						height: 21px;
						margin-right: 18px;
					}
				}

			}
			.btn-done {
				margin-top: 0;
			}


		}
	}
}

.empty-chat {
	text-align: center!important;
	height: calc(100vh - 13.2rem);
	.avatar {
		margin: 0 auto;
	}
}

.contentWidth{
	max-width: 80%;
}

.chat-history {
	padding-bottom: 150px;
	.chat-msg {
		margin-bottom: 54px;
		.chat-msg-content {
			color: #ffffff;
			background-color: #0360EC;
			padding-top: 1rem;
			padding-bottom: 1rem;
			padding-right: 1rem;
			padding-left: 1rem;
			font-size: 1rem;
			border-top-left-radius: 0;
			border-top-right-radius: 1rem;
			border-bottom-right-radius: 1rem;
			border-bottom-left-radius: 1rem;
			.chat-sender-name {
				font-family: "Source Sans Pro";
				font-size: 10px;
				letter-spacing: 0;
				margin-bottom: 0.5rem;
			}
		}
		.chat-time {
			font-size: .7rem;
			color: #9B9B9B;
		}
		a {
			color: #ffffff;
		}

	}
	.chat-msg-self {
		.chat-msg-content {
			background-color: #788998;
			border-top-left-radius: 1rem;
			border-top-right-radius: 0;
			text-align: right;
		}
	}
}



#chat-filter-input{
	//background-image: url('../../media/newicons/search-normal2.png');
	background-repeat: no-repeat;
	background-position: 14px center;
	background-color: #f3f3f3;
	border:0;
	border-radius: 20px;
	height: 40px;
	line-height: 20px;
	padding: .3rem 0 .4rem 2.5rem;
	&:focus{
		border-bottom: 0;
		box-shadow: none;
		&+label{
			display:none;
		}
	}
}

label[for=chat-filter-input] {
	left: 10px;
	&.active {
		display:none;
	}
}

.chat-add-btn {
	background-color: #0360EC;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
	border-radius: 10px;
	width: 130px;
	height: 83px;
	font-size: 16px;
	line-height: 40px;
	text-transform: none;

	color: #ffffff;
	padding: 20px 10px;
	span {
		background-color: #ffffff;
		width: 40px;
		height: 40px;
		padding: 0 10px;
		border-radius: 20px;
		margin-left: 8px;
		img {
			width: 20px;
			height: 20px;
		}
	}
}
.groupchat-add-btn {
	background-color: #30445B;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.groupchat-icon {
	background-color: #0360EC;
	width: 40px;
	height: 40px;
	padding: 8px 8px;
	border-radius: 20px;
	margin-right: 10px;
	img {
		width: 20px;
		height: 20px;
	}
}
.groupchat-icon-white {
	background-color: #ffffff;
}

#attachment {
	//height: 173px;
	//padding-top: 103px;
	padding-bottom:20px;
}

.chat-inputwrapper {
	position: fixed;
	bottom: 0;
	min-height: 80px;
	padding-top: 10px;
	width: 100%;
	.chat-inputcol {
		background-color: #f2efef;
		min-height: 74px;
		.chat-attachment{
			background-color: #ffffff;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			min-height: 93px;
			//margin-top: -93px;
		}
		.chat-form {
			background-color: #ffffff;
			border-radius: 20px;
			height: 54px;
			box-shadow: 0 15px 15px rgba(16,27,79,0.15);

			&.no-top-borderradius{
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
			.chat-form-icon {
				width: 22px;
				height: 22px;
				margin: 16px 8px;
			}
			.chat-form-btn {
				background-color: #ffffff;
				border: 0;
				width: 54px;
				height: 54px;
			}
			.chat-form-control {
				height: 54px;
				border: 0;
			}

		}
	}
}

.chat-chatlistwrapper {
	position: fixed;
	bottom: 0;
	height: 1px;
	padding-top: 10px;
	width: 100%;
	background-color: #f2efef;
	z-index: 10;
	.chatlist-card {
		position: fixed;
		bottom: 0;
	}
}

.chat-emojiwrapper {
	position: fixed;
	bottom: 80px;
	width: 100%;
	z-index: 11;
}


#chatContactTab {
		max-width: 298px;
		.list-group-item {
			padding: .75rem 0;
			border-left:0;
			border-right:0;
			border-top:0;
			color: #000;
			.chat-name {
				color: #101B4F;
				font-size: 16px;
			}
			p {
				color: #9B9B9B;
				margin-bottom: 0;
			}
			span{
				font-size: 10px;
				color: #9B9B9B;
			}
		}
	}

	#chatfilter.switch {
    text-align: center;
  }

	#chatfilter.switch label .lever {
    width: 1.8rem;
    height: 1.2rem;
		background-color: #dccfe2;
  }

#chatfilter.switch label input[type="checkbox"]:checked + .lever::after {
    left: 0.7rem;
    background-color: #0360ec;
}

#chatfilter.switch label .lever::after {
    top: -0.13rem;
    border-radius: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #0360ec;
}



.oval {
  height: 5px;
  width: 5px;
  background-color: #FFFFFF;
	border-radius: 50%;
	margin-bottom: 0.5rem;
}

.oval-online {
	background-color: #62EC03;
}
.oval-offline {
	background-color: #6C757D;
}

.dropdown > [aria-expanded="false"] > .icon-default {
    //content: url("../../media/newicons/groupVerticalGray.png");
}

.dropdown > [aria-expanded="true"] > .icon-default {
  //  content: url("../../media/newicons/groupHorizontalBlue.png");
}

.members-in-the-group {
  color: #FFFFFF;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
}
#tooltip.members-in-the-group {
  height: 14px;
  width: 349px;
	color: #9B9B9B;
}

.chatgroup-name {
  color: #101B4F;
  font-size: 14px;
	margin-bottom: 0px;
}

.groupchat-make {
  color: #30445B;
  font-size: 14px;
  text-align: right;
  img {
	  width: 17px;
	  height: 17px;
  }
}

.chat-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.65);
}

.zip-rectangle {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  background-color: #F2EFEF;
}

.file-12-zip {
  color: #9B9B9B;
  font-family: "Source Sans Pro";
  font-size: 10px;
  text-align: center;
}

.chat-back-btn {
	display: inline-block;
	color: #212529;
	margin-top: 8px;
}


.companies-list-check {
		margin-top: 12px;
		.form-check-input[type="checkbox"]+label {
			padding-left: 24px;
			&:before {
				//background-image: url('../../media/newicons/add.png');
				background-size: cover;
				border:0;
				margin:0;
				width: 20px;
				height: 21px;
			}
		}

		.form-check-input[type="checkbox"]:checked+label:before {
			//background-image: url('../../media/newicons/checkBoxChecked.png');
			background-size: cover;
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
			transform: none;
		}
	}
.addMemberPadding {
	padding-left:25px;
	padding-right: 0px;
}

.addMemberMargin {
	margin-top: -0.5rem;
	margin-bottom: 1rem;
}

.deleteMemberMargin {
	margin-bottom: 0.3rem;
	padding-right: 9px;
}

.groupTopPadding{
	padding-top: 16px;
}

@media (max-width: 575.98px) {
	.groupTopPadding{
		padding-top: 3px;
	}
	.contentWidth{
		max-width: 70%;
	}
	.respoPadding{
		padding-top: 3px;
		padding-left: 20px;
	}
	.chat-header {
		top: 62px;
		.container {
			.chat-header-left {
				margin-top: 5px;
				.icon{
					height: 12px;
					margin-top: 10px;
					margin-left: 10px;
				}
				h5 {
					font-size: 15px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.md-form {
					margin-top: 5px;
					margin-bottom: 0px;
					margin-left: -24px;
				}
				.col-md-9 > div:nth-child(1){
					margin-top: 5px;
					margin-bottom: 0px;
					margin-left: 0px;
				}
				.collapse {
					margin-top: 10px;
				}
				.chat-rename-icon {
					height: 16px;
					width: 16px;
					margin-right: 4px;
					margin-top: -5px;
				}
				/*.members-in-the-group {
					display: inline-block;
					max-width: 60%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-top: 5px;
				}*/
				.search-btn {
					margin-left: 0;
					margin-right: 0;
				}
				.search-col {
					padding-right: 15px;
				}
			}
			.chat-header-left-rename {
				margin-top: 5px;
				.icon{
					height: 12px;
					margin-top: 10px;
					margin-left: 10px;
				}
				h5 {
					font-size: 15px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.md-form {
					margin-top: 4px;
					margin-bottom: 0px;
					margin-left: 10px;
				}
				.collapse {
					margin-top: 10px;
				}
				.chat-rename-icon {
					height: 16px;
					width: 16px;
					margin-right: 4px;
					margin-top: -5px;
				}
				.search-btn {
					margin-left: 0;
					margin-right: 0;
				}
				.search-col {
					padding-right: 15px;
				}
			}
			.chat-header-right {
				border-left: 0;
				.chat-header-atitle {
					font-size: 9px;
				}
				.chat-header-acontent {
					font-size: 10px;
				}

				.md-form {
					margin-top: 10px;
					margin-bottom: 0px;
				}
				.btn-done {
					width: 100%;
					padding: 10px 10px;
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	.card-height {
		height: calc(100vh - 3.7rem);
	}
	.chatgroup-name {
	  color: #101B4F;
	  font-size: 12px;
		margin-bottom: 0px;
	}
	.groupchat-make {
		font-size: 10px;
		line-height: 10px;
		img {
			width: 10px;
			height: 10px;
		}
	}

	.chatlist-card {
		width: 100%;
		left: 0px;
		top: 64px;
		border-radius: 0;
		margin-bottom: 0;
	}

	.chat-add-btn {
		height: 63px;
		padding: 10px 10px;
	}

	.chat-inputwrapper {
		.chat-inputcol {
			.chat-form {
				.chat-form-icon {
					margin: 16px 2px;
				}

			}
		}
	}

	.chat-history {
		.avatar {
			width: 100%;
			height: auto;
			max-width: 100%;
			min-width: 100%;
		}
	}


}

@media (min-width: 576px) and (max-width: 767.98px) {
	.chat-header {
		top: 62px;
		.container {
			.chat-header-left {
				.icon{
					height: 12px;
					margin-top: 10px;
					margin-left: 10px;
				}

				h5 {

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.md-form {
					margin-top: 10px;
					margin-bottom: 0px;
				}
				.chat-rename-icon {
					height: 16px;
					width: 16px;
					margin-right: 4px;
					margin-top: -5px;
				}

				.members-in-the-group {
					display: inline-block;
					max-width: 60%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-top: 5px;
				}
			}

			.chat-header-right {
				border-left: 0;

			}

		}
	}

	.card-height {
		height: calc(100vh - 5.14rem);
	}

	.chatlist-card {
		width: 100%;
		left: 0px;
		border-radius: 0;
		margin-bottom: 0;
	}

	.groupchat-make {
		font-size: 11px;
		img {
			width: 10px;
			height: 10px;
		}
	}

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.chat-header {
		top: 6.8rem;

	}
	.chat-inputwrapper {
		.chat-form {
			.chat-form-icon {
				margin: 16px 4px;
			}

		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.chat-header {
		top: 6.8rem;
		.container {
			.chat-header-left {
				.icon{
					height: 12px;
				}
			}
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1699.98px) {
	.chat-header {
		top: 6.8rem;
		.container {
			.chat-header-left {
				.icon{
					height: 12px;
				}
			}
		}
	}
}
