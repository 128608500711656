.main-page {

}

.bodywrapper{

}

.advertmobile {
	//max-height: 145px;
	margin-bottom: 0.6rem;
	overflow: hidden;

}

.advertwrapper {
	position: fixed;
	top: 8.2rem;
	left: 1rem;
	width: 15%;

}

.list-group-item-main {
	color: rgb(33, 37, 41);
	margin-bottom: 0.6rem;
	border-bottom-left-radius: .7rem;
	border-bottom-right-radius: .7rem;
	border-top-left-radius: .7rem;
	border-top-right-radius: .7rem;
}

.search-tabs {
	height: 46px;
	width: 415px;
	border-radius: 23px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	margin: 0 auto;
	.nav-item {
		width: 33.3%;
		height: 100%;
		text-align: center;
		.nav-link {
			color: #FFFFFF;
			font-family: "Source Sans Pro";
			font-size: 14px;
			padding-top: 10px;
			&.active {
				height: 100%;
				border-radius: 23px;
				background: linear-gradient(90deg, #00C6FA 0%, #0360EC 100%);
			}
		}
	}
}
.search-tabs-chat {
	height: 46px;
	width: 467px;
	border-radius: 23px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	margin: 0 auto;
	.nav-item {
		width: 50%;
		height: 100%;
		text-align: center;
		.nav-link {
			color: #FFFFFF;
			font-family: "Source Sans Pro";
			font-size: 16px;

			&.active {
				height: 100%;
				border-radius: 23px;
				background: linear-gradient(90deg, #00C6FA 0%, #0360EC 100%);
			}
		}
	}
}
.search-col {
	padding-left: 0;
	padding-right: 0;
	max-width: 430px;
	.md-form{
		label {
			left: 10px;
			margin-top: 2px;
			&.active {
				display:none;
			}
		}
		input[type=text].search-input {
			background-color: #ffffff;
			border-bottom: 0;
			border-radius: 24px;
			height: 48px;
			padding-left: 10px;
			padding-top:5px;
			&:focus{
				border-bottom: 0;
				box-shadow: none;
				&+label{
					display:none;
				}
			}
		}
	}
}

.mdb-autocomplete-clear{
	padding-top: 5px;
	padding-right: 10px;
}
.search-col-group {
	padding-left: 0;
	padding-right: 0;
	.md-form{
		max-width: 285px;
		label {
			padding-left: 19px;
			margin-top: -3px;
			&.active {
				display:none;
			}
		}
		input[type=text].search-input {
			background-color: #ffffff;
			border-bottom: 0;
			border-radius: 17px;
			height: 34px;
			padding-left: 10px;
			border: 1px solid #bdbdb3;
			&:focus{
				border-bottom: 0;
				box-shadow: none;
				&+label{
					display:none;
				}
			}
		}
	}
}

.search-col-rename {
	padding-left: 0;
	padding-right: 0;
	.md-form{
		max-width: 285px;
		.form-control{
			margin-top: -0.3rem;
			margin-left: -0.8rem;
			font-size: 14px;
		}
		label {
			left: 10px;
			margin-top: 2px;
			&.active {
				display:none;
			}
		}
		input[type=text].search-input {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid #ced4da;
			border-radius: 0;
			outline: none;
			box-shadow: none;
			transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
			&:focus{
				border-bottom: 0;
				box-shadow: none;
				&+label{
					display:none;
				}
			}
		}
	}
}
.search-logo-col {
	max-width: 78px;
	.search-logo {
		height: 48px;
		width: 48px;
		border-radius: 24px;
		background-color: #ffffff;
		padding: 9px;
		img {
			height: 30px;
			width: 30px;
		}
	}
}
.search-btn-col {
	max-width: 72px;
}
.search-hr {
	border-top: 2px solid rgb(255, 255, 255);
	margin-top: .7rem;
	margin-bottom: 1rem;
}

.search-empty-list {
	width: 80%;
	margin: 0 auto;
}

.btn.btn-sm.search-btn {
	width: 34px;
	height: 34px;
	padding: 0;
}

.btn.btn-sm.gradient-custom {
	background: #ffffff;
  background: -webkit-linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(128, 128, 128, 0.5));
  background: linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(128, 128, 128, 0.5));
}


.modal-content  {
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
}

.photos-content  {
    border-radius: 15px !important;
}

.plusimage-icon  {
    background-color: #e6e6e6;
		width: 50px;
		height: 50px;
}

.offer-text {
  color: #30445B;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-weight: bold;
}

.partner-logo {
	max-width: 60%;
	height: auto;
}
.mitteilungen-msg-content{
  color: #ffffff;
  background-color: #788998;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.8rem;
  padding-left: 0.8em;
  font-size: 0.8rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}

.mitteilungen-time {
    font-size: .7rem;
    color: #9B9B9B;
}

.mitteilungen-name {
	font-family: "Source Sans Pro";
	font-size: 10px;
	letter-spacing: 0;
	margin-bottom: 0.5rem;
}

.mitteilungen-listgroup{
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
}

.adverts-container {
	background-color: rgba(54,54,57, 0.6);
	height: 100%;
	border-radius: 20px;
	padding: 0;
}


@media (max-width: 575.98px) {
	.search-tabs {
		width: 100%;
		.nav-item {
			.nav-link {
				font-size: 10px;
				padding-top: 16px;
			}
		}
	}
	.partner-logo {
		max-width: 35%;
		height: auto;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.search-tabs {
		width: 100%;
		.nav-item {
			.nav-link {
				font-size: 14px;
				padding-top: 12px;
			}
		}
	}
	.partner-logo {
		max-width: 35%;
		height: auto;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.search-tabs {
		width: 100%;
		.nav-item {
			.nav-link {
				font-size: 11px;
				padding-top: 14px;
			}
		}
	}
	.search-col {
		max-width: 220px;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
