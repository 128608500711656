.image-manager-img {
	border-radius: 15px !important;
	height: 150px;
	padding: .5rem!important;
}

.image-manager-img-trash-btn {
	height: 24px;
	width: 24px; 
	top: -47px; 
	right: 47px; 
	margin: 0 -24px 0 0;
	img {
		width: 13px; 
		height: 16px;
	}
}






@media (max-width: 354.98px) {
	.image-manager-img {
		height: 100px;
	}
	.image-manager-img-trash-btn {
		top: -25px; 
		right: 37px;
	}
		
}

@media (min-width: 355px) and (max-width: 374.98px) {
	.image-manager-img {
		height: 110px;
	}
	.image-manager-img-trash-btn {
		top: -27px;
		right: 40px;
	}	
}

@media (min-width: 375px) and (max-width: 409.98px) {
	.image-manager-img {
		height: 110px;
	}
	.image-manager-img-trash-btn {
		top: -27px;
		right: 40px;
	}
		
}


@media (min-width: 410px) and (max-width: 575.98px) {
	.image-manager-img {
		height: 130px;
	}
	.image-manager-img-trash-btn {
		top: -37px;
		right: 40px;
	}	
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.image-manager-img {
		height: 100px;
	}
	
	.image-manager-img-trash-btn {
		top: -27px;
		right: 40px;
	}
		
}