.details-btn {
  height: 35px;
  width: 103px;
  border-radius: 16px;
  background-color: #E7D326;
  text-align: center;
  span {
    height: 19px;
    width: 75.9px;
    color: #FFFFFF;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.79px;
    line-height: 19px;
    text-align: center;
  }
}
.middle-orange {
  height: 20px;
  width: 9.14px;
  border-radius: 16px;
  background-color: #E7D326;
}
.small-orange {
  height: 12.03px;
  width: 5.5px;
  border-radius: 16px;
  background-color: #E7D326;
}
.middle-gray {
  height: 20px;
  width: 9.14px;
  border-radius: 16px;
  background-color: #EFEFEF;
}
.booking-btn {
  height: 35px;
  width: 103px;
  border-radius: 16px;
  background-color: #EFEFEF;
  text-align: center;
  span {
    height: 19px;
    width: 75.9px;
    color: #405A6A;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.79px;
    line-height: 19px;
    text-align: center;
  }
}
.modal-header .btn-close {
  padding: .5rem 3.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  opacity: 1;
}

@media (max-width: 409.98px) {

}

@media (min-width: 410px) and (max-width: 575.98px) {

}

@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
