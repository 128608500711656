.CookieConsent {
	position: fixed;
	bottom: 0px;
	left: 0px;
	align-items: baseline;
	width: 100%;
	z-index: 999;

	background: linear-gradient(-90deg, #12D070 0%, #1E9965 100%);
	color: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	color: white;
	.cookies-content {
		flex: 1 0 330px;
		margin: 15px;
		.cookies-text-title {
			font-size: 16px;
			font-weight: 600;
			img {
				width: 32px;
				height: 32px;
				margin-right: 15px;
			}
		}
		p {
			a {
				color: white;
				text-decoration: underline;
				font-weight: bold;
			}
		}
	}



}

@media (max-width: 575.98px) {
	.CookieConsent {
		.cookies-btn-wrapper {
			margin: 0 auto;
			.btn-akzeptiren-cookies {

				margin-bottom: 20px;
			}
		}
	}
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.CookieConsent {
		.cookies-btn-wrapper {
			margin: 0 auto;
			.btn-akzeptiren-cookies {

				margin-bottom: 20px;
			}
		}
	}
}
