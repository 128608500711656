.btn {
	box-shadow: none;
}
.btn-flat {
	background-color: purple;
	color: white;
}
.btn-invitation{
	
	padding: 0;
	color: #007bff !important;
	margin: 0;
}
.management-btn{
	background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%);
}

.btn-edit-circle {
	border-radius: 50%;
	width:50px;
	height:50px;
	padding: .75rem .75rem;
}

.btn-edit-chat{
  padding: .3rem .3rem;
  text-transform: initial;
  background-color: #fff !important;
  font-size: 1rem;
}

.btn-light.dropdown-toggle {
    background-color: none;
}

.btn-flat {
	.Ripple-outline {
		background: transparent;
	}
}



.btn-done {
	width: 115px;
	height: 50px;
	border-radius: 25px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	color: #FFFFFF;
	padding: 10px 15px;
	text-transform: none;
	font-size: 14px;
	img {
	  	width: 20px;
	  	height: 20px;
	  	margin-top:-2px;
  	}
}

.btn-done-addMember {
    width: 110px;
    height: 34px;
    border-radius: 17px;
    background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
        background-color: rgba(0, 0, 0, 0);
    color: #FFFFFF;
    padding: 6px 15px;
    text-transform: none;
    font-size: 14px;
		img {
		  	width: 20px;
		  	height: 20px;
		  	margin-top:-4px;
	  	}
}

.btn-done-feedback {
	width: 155px;
	height: 46px;
	border-radius: 23px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	color: #FFFFFF;
	padding: 10px 15px;
	text-transform: none;
	font-size: 14px;
	img {
	  	width: 20px;
	  	height: 20px;
	  	margin-top:-2px;
  	}
}
.btn-done-speichern {
	width: 155px;
	height: 46px;
	border-radius: 23px;
	background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%);
	color: #FFFFFF;
	padding: 10px 15px;
	text-transform: none;
	font-size: 14px;
	img {
	  	width: 20px;
	  	height: 20px;
	  	margin-top:-2px;
  	}
}

.btn-done-cookies {
	width: 160px;
	height: 46px;
	border-radius: 23px;
	background-color: transparent;
	color: #FFFFFF;
	border: 1px solid #FFFFFF;
	padding: 10px 15px;
	text-transform: none;
  font-family: "Source Sans Pro";
  font-size: 14px;
  text-align: center;

}

.btn-akzeptiren-cookies {
	width: 150px;
	height: 46px;
	border-radius: 23px;
	padding: 10px 15px;
	background-color: #FFFFFF;
	color: #30445B;
	font-size: 14px;
	text-transform: none;
	margin-top: 15px;
}

.picker__footer .clockpicker-button {
	width: 115px;
	height: 50px;
	border-radius: 25px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	color: #FFFFFF;
	padding: 10px 15px;
	text-transform: none;
	font-size: 14px;

}

span.btn-floating {
	margin: 9.5px;
}

.userpage-btn {
	width: 168px;
	height: 46px;
	border-radius: 23px;
	padding: 6px;
	background: linear-gradient(-90deg, #00C6FA 0%, #0360EC 100%);
	text-transform: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 22px;
	img {
		width: 34px;
		height: 34px;
	}
}

@media (max-width: 575.98px) {
	.userpage-btn {
		width: 130px;
		height: 40px;
		border-radius: 20px;
		padding: 6px;
		img {
			width: 30px;
			height: 30px;
		}
	}
}
