.profile-header{
	.profile-header-bg {
		width: 100%;
		min-height: 300px;
		background-size: cover;
	}
	.profile-header-content {
		background: #ffffff;
		height: 70px;
		.profile-image {
			cursor: pointer;
			width: 300px;
			height: 300px;
			margin-top: -152px;
			border: 5px solid #ffffff;
		}
		.profile-image-company {
			width: 300px;
			height: 300px;
			margin-top: -152px;
			border: 5px solid #ffffff;
		}
		.profile-header-btns {
			margin-top: -300px;
			.profile-header-edit-btn {
				background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%) !important;
				img {
					width: 28px;
					height: 28px;
					margin-bottom: -23px;
					margin-right: -2px;
				}
			}
		}
	}
}

.profile-content {
	margin-top: 80px;
	.profile-ib2bicon {
		width: 55%;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.profile-mapholder {
		border: 20px solid #ffffff;
		border-radius: 20px;
		margin-bottom: 20px;
	}
}

.profile-edit-btn {
	background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%) !important;
	img {
		width: 28px;
		height: 28px;
		margin-bottom: -23px;
		margin-right: -2px;
	}
}

.company_enable {
	height: 30px;
	max-width: 435px;
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 11px;
	letter-spacing: 0px;
	line-height: 20px;
	text-align: center;
}

.company_enable_search {
	height: 30px;
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 11px;
	letter-spacing: 0px;
	line-height: 20px;
	text-align: center;
}

.company_assign {
	height: 30px;
	color: #30445B;
	font-family: "Source Sans Pro";
	font-size: 12px;
	letter-spacing: 0px;
	line-height: 20px;
	text-align: center;
}

.company_enable_hier {
	color: #007bff !important;
	text-decoration-line: underline;
}


@media (max-width: 575.98px) {
	.profile-header{
		.profile-header-bg {
			min-height: 70px;
		}
		.profile-header-content {
			.profile-image {
				width: 70px;
				height: 70px;
				margin-top: -36px;
				border: 2px solid #ffffff;
			}
			.profile-image-company {
				width: 70px;
				height: 70px;
				margin-top: -36px;
				border: 2px solid #ffffff;
			}
			.profile-header-btns {
				margin-top: -64px;
				.profile-header-edit-btn {
					margin-right: 10px;
					width: 37px;
					height: 37px;
					img {
						width: 25px;
						height: 25px;
						margin-bottom: -14px;
						margin-right: 0px;
					}
				}
			}
		}
	}

	.profile-content {
		margin-top: 20px;
		.profile-ib2bicon {
			margin-top: 0px;
		}

		.profile-mapholder {
			border-width: 5px;
			border-radius: 5px;
		}
	}

	.profile-edit-btn {
		width: 37px;
		height: 37px;
		img {
			width: 25px;
			height: 25px;
			margin-bottom: -14px;
			margin-right: 0px;
		}
	}

	.profile-uberuns-edit-btn {
		margin-right: -15px;
	}
	.company_enable {
		height: 50px;
		max-width: 269px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.profile-header{
		.profile-header-bg {
			min-height: 110px;
		}
		.profile-header-content {
			.profile-image {
				width: 110px;
				height: 110px;
				margin-top: -56px;
				border: 2px solid #ffffff;
			}
			.profile-image-company {
				width: 110px;
				height: 110px;
				margin-top: -56px;
				border: 2px solid #ffffff;
			}
			.profile-header-btns {
				margin-top: -108px;
			}
		}
	}

	.profile-content {
		margin-top: 20px;
		.profile-ib2bicon {
			margin-top: 0px;
		}

		.profile-mapholder {
			border-width: 8px;
			border-radius: 8px;
		}
	}

	.profile-uberuns-edit-btn {
		margin-right: -15px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.profile-header{
		.profile-header-bg {
			min-height: 170px;
		}
		.profile-header-content {
			.profile-image {
				width: 170px;
				height: 170px;
				margin-top: -86px;
				border: 2px solid #ffffff;
			}
			.profile-image-company {
				width: 170px;
				height: 170px;
				margin-top: -86px;
				border: 2px solid #ffffff;
			}
			.profile-header-btns {
				margin-top: -170px;
			}
		}
	}

	.profile-content {
		margin-top: 20px;
		.profile-ib2bicon {
			margin-top: 10px;
		}
		.profile-mapholder {
			border-width: 10px;
			border-radius: 10px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.profile-header{
		.profile-header-bg {
			min-height: 200px;
		}
		.profile-header-content {
			.profile-image {
				width: 200px;
				height: 200px;
				margin-top: -102px;
				border: 4px solid #ffffff;
			}
			.profile-image-company {
				width: 200px;
				height: 200px;
				margin-top: -102px;
				border: 4px solid #ffffff;
			}
			.profile-header-btns {
				margin-top: -200px;
			}
		}
	}

	.profile-content {
		margin-top: 20px;

		.profile-mapholder {
			border-width: 15px;
			border-radius: 15px;
		}
	}
}
