.bike-picture {
	height: 240px;
	width: auto;
}
.anzeige-text {
	height: 22px;
	width: 235px;
	color: #4C4A33;
	font-family: "Work Sans";
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.59px;
	line-height: 18px;
}
.book-btn {
	height: 46px;
  width: 97px;
  border-radius: 15px;
  background-color: #E7D326;
  	margin: 0;
	padding: 0;
	text-transform: unset !important;
	span {
		height: 19px;
		width: 75.9px;
		color: #4C4A33;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.79px;
		line-height: 19px;
		text-align: center;
	}
}
.link-text {
	height: 19px;
	width: 165px;
	color: #E7D326;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.79px;
	line-height: 19px;
}
.price-text {
	height: 19px;
	width: 80px;
	color: #4C4A33;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.79px;
	line-height: 19px;
	text-align: center;
	display:inline-flex;
}
.rent-e-bike {
	height: 100px;
  width: auto;
}
.blitz-logo {
	height: 71.33px;
	width: 62px;
}
.bring-back {
	height: 100px;
  width: auto;
}


.landing-nav-container, .landing-nav-container-scroll {
	transition: padding-top 0.6s, background-color 0.6s;
	padding-right: 300px;
	.landing-nav {
		justify-content: flex-end !important;
		.nav-item {
			.nav-link {
				min-width: 116px;
				color: #30445B;
				text-align: center;
				//padding: 30px 10px 0;
				span{
					font-size: 16px;
					display: block;
					white-space: nowrap;
				}
				&.active {
					&:after {
						content: "";
						background: linear-gradient(90deg, rgba(0,198,250,1) 0%, rgba(3,96,236,1) 100%);
						width: 80px;
						height: 7px;
						display: block;
						margin: 5px auto 0px;
						border-radius: 4px;
					}
				}
			}
		}
	}
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: transparent!important;
	background-image: url('../../media/images/combined-shape.png')!important;
	background-size: 100% auto!important;
	height: 100% !important;
	width: 100% !important;
	margin-top: 0px !important;
	margin-left: 0px !important;
}

#fp-nav ul li, .fp-slidesNav ul li {
  display: block;
	height: 23.27px !important;
	width: 20.23px !important;
  margin: 0px;
  position: relative;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  z-index: 1 !important;
  border: 0 !important;
  left: 0% !important;
  top: 0% !important;
}

/*.fp-viewing-fourthPage #fp-nav ul li:hover a.active span,
.fp-viewing-fourthPage #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-image: url('../../media/images/combined-shape-w.png')!important;
	background-color: transparent!important;
	background-size: 110% auto!important;
	height: 110% !important;
	width: 110% !important;
	margin-top: -3px !important;
	margin-left: -6px !important;
}*/

.fp-viewing-thirdPage #fp-nav ul li:hover a.active span,
.fp-viewing-thirdPage #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-image: url('../../media/images/combined-shape-w.png')!important;
	background-color: transparent!important;
	background-size: 100% auto!important;
	height: 100% !important;
	width: 100% !important;
	margin-top: 0px !important;
	margin-left: 0px !important;
}
.fp-viewing-lastPage #fp-nav ul li:hover a.active span,
.fp-viewing-lastPage #fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
	background-image: url('../../media/images/combined-shape-w.png')!important;
	background-color: transparent!important;
	background-size: 100% auto!important;
	height: 100% !important;
	width: 100% !important;
	margin-top: 0px !important;
	margin-left: 0px !important;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    height: 10px!important;
    width: 10px!important;
    background: #E7D326!important;
    margin: 9px 0 0 6px!important;
}

/*.fp-viewing-fourthPage #fp-nav ul li a span, .fp-slidesNav ul li a span {
    background: #ffffff!important;
}*/

.fp-viewing-thirdPage #fp-nav ul li a span, .fp-slidesNav ul li a span {
    background: #ffffff!important;
}
.fp-viewing-lastPage #fp-nav ul li a span, .fp-slidesNav ul li a span {
    background: #ffffff!important;
}

#fp-nav ul li .fp-tooltip {
    top: 0px!important;
    color: #E7D326!important;
    font-size: 18px!important;
		font-weight: bold!important;
}

/*.fp-viewing-fourthPage #fp-nav ul li .fp-tooltip {
    color: #ffffff!important;
}*/

.fp-viewing-thirdPage #fp-nav ul li .fp-tooltip {
    color: #ffffff!important;
}
.fp-viewing-lastPage #fp-nav ul li .fp-tooltip {
    color: #ffffff!important;
}

#fp-nav ul li .fp-tooltip.fp-right {
    right: 25px!important;
}

#fp-nav ul li, .fp-slidesNav ul li {
    height: 25px!important;
}

#fp-nav.fp-right {
    right: 50px!important;
}


#fp-nav.fp-show-active a.active + .fp-tooltip {
  opacity: 0.6!important;
}

#fp-nav ul li:hover .fp-tooltip {
	opacity: 1!important;
}



.fluid-container {
	padding: 0;
}
.e-fleet-management{
	//height: 190px;
  width: 682px;
  color: #E7D326;
  font-family: "Work Sans";
  font-size: 74px;
  font-weight: 800;
  letter-spacing: 3.63px;
  line-height: 87.66px;
}

.landing-tesla {
	height: 105.81px;
	width: 200px;
}

.landing-long-term-rental {
	height: 22px;
  width: 235px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.59px;
  line-height: 18px;
}

.landing-tesla-from {
	height: 22px;
	width: 125px;
	color: #405A6A;
	font-family: "Work Sans";
	font-size: 16px;
	//font-weight: bold;
	letter-spacing: 0.59px;
	line-height: 22px;
}

.landing-tesla-price {
	height: 22px;
	width: 125px;
	color: #405A6A;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.59px;
	line-height: 22px;
}

.tesztvezetes-btn-landing {
	height: 46px;
	width: 170px;
	border-radius: 15px;
	padding-top: 10px;
	padding-left: 0px;
	padding-right: 0px;
	background-color: #12D070!important;
	text-transform: none;
	font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.79px;
  line-height: 22px;
  text-align: center;
	&:hover {
			color: #FFF;
			background-color: #FFFFFF;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
	}
}

.navbar-logo-footer {
	height: auto;
	width: 147.79px;
}

.accumulator {
  height: auto;
  width: 46px;
}

.atmosphere {
  height: auto;
  width: 42px;
}

.biofuel {
  height: auto;
  width: 48px;
}

.car-e-1 {
  height: 100px;
  width: auto;
}

.car-e-10 {
	height: 70px;
	width: auto;
}

.fix-height {
	height: 35px;
}

.electric-tower {
  height: auto;
  width: 46px;
}

.electric-car {
  height: auto;
  width: 44.92px;
}

.battery {
  height: auto;
  width: 32.3px;
}

.wind {
  height: 135px;
  width: auto;
}

.lorem-w {
  height: 30px;
  width: 430px;
  color: #4C4A33;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.79px;
  line-height: 22px;
}

.lorem-w-t {
  height: 61px;
  width: 430px;
  color: #4C4A33;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.59px;
  line-height: 16px;
}

.lorem-b {
  height: 30px;
  width: 410px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.79px;
  line-height: 22px;
}

.lorem-b-t {
  height: 61px;
  width: 410px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.59px;
  line-height: 16px;
}

.rental {
	height: 90.66px;
  width: 559px;
  color: #4C4A33;
  font-family: "Work Sans";
  font-size: 74px;
  font-weight: bold;
  letter-spacing: 3.63px;
  line-height: 90.66px;
}

.services {
	height: 90.66px;
  width: 559px;
  color: #4C4A33;
  font-family: "Work Sans";
  font-size: 74px;
  font-weight: bold;
  letter-spacing: 3.63px;
  line-height: 90.66px;
}

.right-col {
	padding-left: 4rem;
}

.in-safe {
	height: 90.66px;
  width: 1333px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 74px;
  font-weight: 800;
  letter-spacing: 3.63px;
  line-height: 90.66px;
}

.in-safe-top {
	height: 86px;
	width: 312px;
	color: #12D070;
	font-family: "Work Sans";
	font-size: 34px;
	font-weight: 800;
	letter-spacing: 1.67px;
	line-height: 43px;
}

.in-safe-middle {
	width: 320px;
	color: #12D070;
	font-family: "Work Sans";
	font-size: 12px;
	letter-spacing: 0.69px;
	line-height: 19px;
}

.in-safe-bottom {
	height: 54px;
  width: 312px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 12px;
  letter-spacing: 0.59px;
  line-height: 16px;
}

.minta-akku {
  height: 64.86px;
  width: 64.86px;
}

.landing-email-form {
	//width: 540px;
	padding-left: 33px;
	width: 100%;
}

.landing-email-kapcsolat {
	height: 91px;
  width: 554px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 74px;
  font-weight: 800;
  letter-spacing: 3.63px;
  line-height: 90.66px;
}

.your-email-label {
  height: 22px;
  width: 164px;
  color: #405A6A;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.79px;
  line-height: 22px;
}


	#defaultFormContactMessageEx.form-control {

		display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
		resize: none;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.2);
		border-radius: 10px;
		background-color: #FFFFFF;
		box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.1);

}

#defaultFormContactCompanyNameEx.form-control {

	display: block;
	width: 100%;
	height: 48px;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	resize: none;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.1);

}

#defaultFormContactSubjectEx.form-control {

	display: block;
	width: 100%;
	height: 48px;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.1);

}

#defaultFormContactEmailEx.form-control {

	display: block;
	width: 100%;
	height: 48px;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.1);

}

.landing-btn-send-email {
	height: 46px;
	width: 140.57px;
	border-radius: 15px;
	padding-top: 12px;
	padding-left: 0px;
	padding-right: 0px;
	margin: 0rem;
	font-family: "Work Sans";
	background-color: #E7D326!important;
	text-transform: none;
	font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.79px;
  line-height: 22px;
  text-align: center;
	color: #FFFFFF;
	&:hover {
	    color: #FFF;
	    background-color: #FFFFFF;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
	}
}

.landing-phonenumber {
	height: 46px;
	width: 210px;
	border-radius: 15px;
	background-color: rgba(0,0,0,0.36);
	.landing-phonenumber-text {
		height: 22px;
	  //width: 155.84px;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 16px;
	  font-weight: 800;
	  letter-spacing: 0.79px;
	  line-height: 22px;
	  text-align: center;
		padding-top: 12px;
	}
}

.dijmentes-tanacsadas {
	height: 120px;
	width: 441px;
	color: #FFFFFF;
	font-family: "Work Sans";
	font-size: 31px;
	font-weight: 800;
	letter-spacing: 1.52px;
	line-height: 40px;
}


.tesztvezetes-btn-landing-invers {
	height: 46px;
	width: 170px;
	border-radius: 15px;
	padding-top: 12px;
	padding-left: 0px;
	padding-right: 0px;
	margin: 0;
	background-color: #FFFFFF!important;
	text-transform: none;
	font-family: "Work Sans";
	font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.79px;
  line-height: 22px;
  text-align: center;
	color: #405A6A;
	&:hover {
	    color: #111;
	    background-color: #FFFFFF;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
	}
}


.landing-section1-row {
	//min-height: 80vh;
}
.landing-section2-row {
	//height: 84vh;
	padding-top: 20px;
}
.landing-section3-row {
	//height: 14.3vh;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.landing-section4-row{
	//height: 100vh;
}

.landing-e-fleet-row {
	padding-bottom: 0rem;
	padding-top: 2rem;
}
.landing-e-fleet-row-second {
	padding-bottom: 2rem;
	padding-top: 0rem;
}
.landing-e-fleet-row1 {
	padding-bottom: 0rem;
	padding-top: 0rem;
	width: 500px;
}
.landing-e-fleet-row2 {
	padding-bottom: 0rem;
	padding-top: 0rem;
	width: 500px;
}
.landing-e-fleet-row3 {
		padding-bottom: 5rem;
		padding-top: 0rem;
		width: 500px;
	}
.e-fleet-management-l{
		color: #12D070;
		font-family: "Work Sans";
		font-size: 45px;
		font-weight: 800;
		letter-spacing: 1.77px;
		padding-left: 5px;
		text-align: left;
	}
	.e-fleet-management-c{
		color: #12D070;
		font-family: "Work Sans";
		font-size: 45px;
		font-weight: 800;
		letter-spacing: 1.77px;
		padding-left: 0px;
		text-align: center;
	}
	.e-fleet-management-r{
		color: #12D070;
		font-family: "Work Sans";
		font-size: 45px;
		font-weight: 800;
		letter-spacing: 1.77px;
		padding-right: 5px;
		text-align: right;
	}
.e-fleet-management-grun{
	font-size: 45px;
	padding-bottom: 0rem;
	padding-top: 0rem;
	height: 55px;
}
.landing-rental-row {
	//height: 10vh;
	padding-bottom: 2rem;
}
.landing-lorem-row {
	padding-bottom: 2rem;
}
.landing-about-row {
	//height: 10vh;
}
.landing-in-save-row {
	//height: 13vh;
	padding-bottom: 2rem;
}
.in-safe-icon {
	width: 312px;
	padding-bottom: 1rem;
	//height: 20vh;
}
.in-safe-middle-row {
	//height: 23vh;
	padding-bottom: 1rem;
}
.in-safe-bottom-row {
	//height: 15vh;
}
.landing-link-btn-tesztvezetes-row {
	//height: 30vh;
}
.landing-link-row {
	//height: 30vh;
	width: 480px;
}
.landing-symbol-icon {
	//height: 18vh;
}
.landing-e-car-wind-img {
	//height: 20vh;
}
.landing-col-icon {
	padding-left: 0;
}
.landing-kapcsolat-row {
	//height: 16vh;
	width: 100%;
	padding-bottom: 0.5rem;
}
.landing-email-kapcsolat-email {
	//height: 12vh;
	width: 100%;
	//padding-bottom: 2vh;
}
.landing-email-kapcsolat-text {
	//height: 25vh;
	width: 100%;
	//padding-bottom: 5vh;
}
.landing-email-kapcsolat-button {
	//height: 15vh;
	width: 100%;
}
.landing-padding-right {
	padding-right: 12rem;
	padding-left: 2rem;
}
.landing-padding-right-kapcsolat{
	padding-left: 1rem;
}
.landing-form-email-row{
	padding-bottom: 1.5rem;
  width: 100%;
}

@media (max-width: 409.98px) {

}

@media (min-width: 410px) and (max-width: 575.98px) {

}

@media (max-width: 575.98px) {
	.e-fleet-management{
		height: 95px;
		//width: 285px;
		color: #12D070;
		font-family: "Work Sans";
		font-size: 36px;
		font-weight: 800;
		letter-spacing: 1.77px;
		line-height: 43.76px;
		padding-left: 15px;
	}
	.e-fleet-management-l{
			color: #12D070;
			font-family: "Work Sans";
			font-size: 45px;
			font-weight: 800;
			letter-spacing: 1.77px;
			padding-left: 15px;
			text-align: left;
		}
		.e-fleet-management-c{
			color: #12D070;
			font-family: "Work Sans";
			font-size: 45px;
			font-weight: 800;
			letter-spacing: 1.77px;
			padding-left: 0px;
			text-align: center;
		}
		.e-fleet-management-r{
			color: #12D070;
			font-family: "Work Sans";
			font-size: 45px;
			font-weight: 800;
			letter-spacing: 1.77px;
			padding-right: 20px;
			text-align: right;
		}
	.e-fleet-management-grun{
		font-size: 28px;
		padding-bottom: 0rem;
		padding-top: 0rem;
		height: 40px;
	}

	.landing-tesla {
		height: 169.29px;
		width: 320px;
	}
	.landing-section1-row {
		//height: 100vh;
	}
	.landing-section2-row {
		//height: 100vh;
		padding-top: 0px;
	}
	.landing-e-fleet-row {
		padding-bottom: 0rem;
		padding-top: 2rem;
	}
	.landing-e-fleet-row1 {
		padding-bottom: 0rem;
		padding-top: 0rem;
		width: 340px;
	}
	.landing-e-fleet-row2 {
		padding-bottom: 0rem;
		padding-top: 0rem;
		width: 340px;
	}
	.landing-e-fleet-row3 {
			padding-bottom: 0rem;
			padding-top: 0rem;
			width: 340px;
		}
	.landing-link-btn-tesztvezetes-row {
		//min-height: 50vh;
	}
	.landing-link-row {
		//height: 38vh;
		width: 350px;
	}
	.landing-tesla-col {
		//height: 25vh;
	}
	.landing-symbol-icon {
		//height: 23vh;
	}
	.landing-checkout-col {
		//height: 13vh;
	}
	.landing-rental-row {
		//height: 10vh;
		padding-left: 18px;
		padding-bottom: 1rem;
	}
	.landing-about-row {
		padding-bottom: 40px;
		padding-left: 18px;
	}
	.rental {
		height: 50px;
		width: 280px;
		color: #FFFFFF;
		font-family: "Work Sans";
		font-size: 36px;
		font-weight: 800;
		letter-spacing: 1.77px;
		line-height: 55px;
	}
	.services {
		height: 60px;
		width: 280px;
	  color: #405A6A;
	  font-family: "Work Sans";
		font-size: 36px;
		font-weight: 800;
		letter-spacing: 1.77px;
		line-height: 90.66px;
	}
	.lorem-w {
		height: 30px;
	  width: 215px;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 16px;
	  font-weight: 800;
	  letter-spacing: 0.79px;
	  line-height: 22px;
	}

	.lorem-w-t {
		height: 135px;
	  width: 215px;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 12px;
	  letter-spacing: 0.59px;
	  line-height: 17px;
	}

	.lorem-b {
	  height: 30px;
	  width: 410px;
	  color: #405A6A;
	  font-family: "Work Sans";
	  font-size: 16px;
	  font-weight: bold;
	  letter-spacing: 0.79px;
	  line-height: 22px;
	}

	.lorem-b-t {
		height: 135px;
	  width: 215px;
	  color: #405A6A;
	  font-family: "Work Sans";
	  font-size: 12px;
	  letter-spacing: 0.59px;
	  line-height: 17px;
	}
	.car-e-1 {
	  height: 65px;
	  width: auto;
	}
	.fix-height {
		height: 0px;
	}
	.accumulator {
	  height: auto;
	  width: 66px;
	}

	.atmosphere {
	  height: auto;
	  width: 66px;
	}

	.biofuel {
	  height: auto;
	  width: 66px;
	}

	.electric-tower {
	  height: auto;
	  width: 66px;
	}

	.electric-car {
	  height: auto;
	  width: 66px;
	}

	.battery {
	  height: auto;
	  width: 40px;
	}

	.wind {
	  height: 130px;
	  width: auto;
	}
	.right-col {
		padding-left: 15px;
		padding-top: 3.5rem;
	}
	.left-col {
		height: 100%;
	}
	.landing-e-car-img {
		//height: 14vh;
		padding-bottom: 10px;
	}
	.landing-wind-img {
		padding-bottom: 2rem
	}
	.landing-col-icon {
		padding-left: 15px;
	}
	.in-safe {
		height: 60px;
	  width: 280px;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 36px;
	  font-weight: 800;
	  letter-spacing: 1.77px;
	  line-height: 90.66px;
	}

	.in-safe-top {
		height: 22px;
	  width: 279px;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 16px;
	  font-weight: 800;
	  letter-spacing: 0.79px;
	  line-height: 22px;
	}

	.in-safe-middle {
	  width: 320px;
	  color: #FFFFFF;
	  font-family: "Work Sans";
	  font-size: 12px;
	  letter-spacing: 0.59px;
	  line-height: 17px;
	}

	.in-safe-bottom {
		height: 54px;
		width: 279px;
		color: #405A6A;
		font-family: "Work Sans";
		font-size: 12px;
		letter-spacing: 0.59px;
		line-height: 16px;
	}
	.landing-section3-row {
		//height: 11.3vh;
		padding-left: 18px;
		padding-bottom: 2rem;
		padding-top: 0;
	}

	.landing-section4-row{
		//min-height: 1000px;
	}
	.landing-in-save-row {
		//height: 5vh;
		padding-left: 18px;
		padding-bottom: 1rem;
	}
	.minta-akku {
	  height: 59.5px;
	  width: 59.5px;
	}
	.in-safe-middle-row {
		//height: 27vh;
		padding-left: 18px;
		padding-bottom: 2rem;
	}
	.in-safe-bottom-row {
		//height: 12vh;
		padding-left: 18px;
		padding-bottom: 1rem;
	}
	.in-safe-icon {
		width: 340px;
		padding-bottom: 1rem;
		//height: 11vh;
		padding-left: 18px;
	}
	.landing-kapcsolat-row {
		//height: 16vh;
		width: 100%;
		padding-bottom: 2rem;
	}
	.landing-email-kapcsolat {
		height: 60px;
	  width: 280px;
	  color: #405A6A;
	  font-family: "Work Sans";
	  font-size: 36px;
	  font-weight: 800;
	  letter-spacing: 1.77px;
	  line-height: 90.66px;
	}
#defaultFormContactMessageEx.form-control {
	//width: 328px;
	height: 136px;
}

#defaultFormContactSubjectEx.form-control {
	//width: 328px;
	height: 48px;
}

#defaultFormContactCompanyNameEx.form-control {
	height: 48px;
}

#defaultFormContactEmailEx.form-control {
	//width: 328px;
	height: 48px;
}
.landing-email-kapcsolat-email {
	//height: 12vh;
	//width: 328px;
	//padding-bottom: 2vh;
}
.landing-email-kapcsolat-text {
	//height: 27vh;
	//width:328px;
	//padding-bottom: 5vh;
}
.landing-email-kapcsolat-button {
	//height: 100%;
	//width: 328px;
	margin-left: -3px;
	//padding-bottom: 15px;
}

.landing-email-form {
	//width: 328px;
	padding-left: 18px;
}
.landing-btn-send-email {
	width: 100%;
}
.landing-margin-section {
	margin-top: -100%;
}
.landing-tesla-from {
  font-size: 12px;
}
.landing-lorem-row {
	padding-bottom: 0.7rem;
}
.landing-padding-right {
	padding-right: 0;
	padding-left: 1.1rem;
}
.landing-padding-right-kapcsolat{
	padding-left: 1rem;
}
.landing-form-email-row{
	padding-bottom: 1.5rem;
	margin-left: -3px;
  width: 100%;
}
.collapse-border {
	box-sizing: border-box;
	border: 1px solid #FFFFFF;
	border-radius: 14px;
	width: 310px;
}
.arrow-white {
	height: 10.04px;
  width: auto;
}
.collapse-titel {
	color: #FFFFFF;
	font-family: "Work Sans";
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 0.79px;
	line-height: 22px;
}
#collapse-header.card-header {
  border-bottom: none;
}
.collapse-text {
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 12px;
  letter-spacing: 0.59px;
  line-height: 17px;
}
.collapse-text-row {
	width: 310px;
	margin: 0;
}
.without-height {
	height: 0px!important;
}
.fp-tableCell {
  height: 0px!important;
}
.rectangle-autos {
  height: 31px;
	width: 100%;
  border-radius: 10.11px;
  background-color: #F2F2F2;
	align-items: center;
	display: grid;
}
.rectangle-autos-name {
  color: #434343;
  font-family: "Work Sans";
  font-size: 16px;
  letter-spacing: 0.79px;
  text-align: center;
}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
