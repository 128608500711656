.navbar.navbar-light .navbar-nav .nav-item.active>.nav-link {
	background-color: transparent;

}



.dropdown{
	.dropdown-menu{
		will-change: transform;
		top: 3px;
		left: -62px;
		transform: translate3d(0px, 107px, 0px);
	}
}

.navbar {
  font-weight: 300;
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0.16),0 0px 0px 0 rgba(0,0,0,0.12);

	.navbarcomponent-nav-container, .navbarcomponent-nav-container-scroll {
		transition: padding-top 0.6s, background-color 0.6s;
		max-width: 1460px;
		padding-top: 25px;
		.navbar-brand{
				img{
					height: auto;
					width: 70px;
				}
		}
		.navbarcomponent-nav {
			justify-content: flex-end !important;
	      height: 46px;
	      width: 620px !important;
	      border-radius: 15px;
	      background-color: #F2F2F2;
	    	margin: 0 auto;
	    	.nav-item {
	        .clicknride {
						display: inline-block;
	      		text-align: center;
	          height: 22px;
	          width: 116px;
	          color: #434343;
	          font-family: "Source Sans Pro";
	          font-size: 16px;
	          font-weight: 500;
	          letter-spacing: 0.79px;
	          line-height: 10px;
	        }
	        .cross {
						display: inline-block;
	      		text-align: center;
	          height: 22px;
	          width: 78px;
	          color: #434343;
	          font-family: "Source Sans Pro";
	          font-size: 16px;
	          font-weight: 500;
	          letter-spacing: 0.79px;
	          line-height: 10px;
	        }
	        .city {
						display: inline-block;
	          text-align: center;
	          height: 22px;
	          width: 75px;
	          color: #434343;
	          font-family: "Source Sans Pro";
	          font-size: 16px;
	          font-weight: 500;
	          letter-spacing: 0.79px;
	          line-height: 10px;
	        }
	        .touring {
						display: inline-block;
	      		text-align: center;
	          height: 22px;
	          width: 78px;
	          color: #434343;
	          font-family: "Source Sans Pro";
	          font-size: 16px;
	          font-weight: 500;
	          letter-spacing: 0.79px;
	          line-height: 10px;
	        }
	        .kids {
						display: inline-block;
	      		text-align: center;
	          height: 22px;
	          width: 78px;
	          color: #434343;
	          font-family: "Source Sans Pro";
	          font-size: 16px;
	          font-weight: 500;
	          letter-spacing: 0.79px;
	          line-height: 10px;
	        }
	        .scout {
						display: inline-block;
	      		text-align: center;
	          height: 22px;
	          width: 99px;
	          color: #434343;
	          font-family: "Source Sans Pro";
	          font-size: 16px;
	          font-weight: 500;
	          letter-spacing: 0.79px;
	          line-height: 10px;
	        }
					.navbar-notgreen{
						.nav-link {
		    			color: #FFFFFF;
		    			font-family: "Source Sans Pro";
		    			font-size: 14px;
		    			padding-top: 10px;
		    			&.active {
		            height: 46px;
		            border-radius: 15px;
		            background-color: #E7D326;
		            .navbar-text {
		              color: #FFFFFF;
		              font-weight: bold;
		            }
		    			}
		    		}
					}
					.navbar-green{
						.nav-link {
		    			color: #FFFFFF;
		    			font-family: "Source Sans Pro";
		    			font-size: 14px;
		    			padding-top: 10px;
		    			&.active {
		            height: 46px;
		            border-radius: 15px;
		            background-color: #F7F0B5;
		            .navbar-text {
		              color: #E7D326;
		              font-weight: bold;
		            }
		    			}
		    		}


	    	}
	    }
		}
	}
}

.navbar-row{
	margin: 0;
}
.phonenumber {
	height: 46px;
	width: 196px;
	border-radius: 15px;
	background-color: rgba(0,0,0,0.36);
	.phonenumber-text {
		color: #FFFFFF;
		font-family: "Source Sans Pro";
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.79px;
		line-height: 43px;
		text-align: center;
		text-shadow: 0 6px 12px 0 rgba(255,255,255,0.5);
	}
}

.fixed-top-navbar {
    z-index: 1000;
		position: fixed;
		top: 0;
		width: 100%;
}

.footer-div {
    height: 92px;
    width: 100%;
    background-color: #2C2C2C;
    position: absolute;
    bottom: 0;
}

.navbar-background{
	height: 92px;
	width: 100%;
	background-color: #ffffff;
	margin-top: -8.2rem;
	//position: fixed;
	//top: 0;
}

.footer-text {
	height: 16px;
	width: 108px;
	color: #FFFFFF;
	font-family: "Source Sans Pro";
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.59px;
	line-height: 16px;
}


@media (max-width: 575.98px) {
	.navbar {
		transition: padding-top 0.6s, background-color 0.6s;
		//max-width: 350px;
		margin-top: 8px;
		margin-left: 5px;
		margin-right: 5px;
		padding-top: 0.2rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;
		height: 46px;
		border-radius: 15px;
		background-color: #F2F2F2;
		padding-bottom: 10px;
		.collapse {
			overflow-y: scroll;
			max-height: calc(100vh - 4rem);
		}
		.navbar-toggler-icon{
			background-image: url('../media/images/hamburgermenu.png') !important;
			background-size: 19.5px 15.5px;
		}

		.navbarcomponent-nav-container{
			padding-top: 0px;
			.navbar-brand{
					img{
						height: 28px;
				    width: 106.4px;
						margin-top: -0.3rem;
					}
			}
			.navbarcomponent-nav {
				justify-content: flex-end !important;
		      height: 100%;
		      width: 305px;
		      border-radius: 0px;
		      background-color: #FFFFFF;
		    	margin: 0 auto;
					padding-left: 15px;
					padding-top: 35px;
		    	.nav-item {
		        .offers {
		      		text-align: left;
		          height: 22px;
		          width: 78px;
		          color: #434343;
		          font-family: "Source Sans Pro";
		          font-size: 16px;
		          font-weight: 500;
		          letter-spacing: 0.79px;
		          line-height: 10px;
		        }
		        .tesla {
		      		text-align: left;
		          height: 22px;
		          width: 78px;
		          color: #434343;
		          font-family: "Source Sans Pro";
		          font-size: 16px;
		          font-weight: 500;
		          letter-spacing: 0.79px;
		          line-height: 10px;
		        }
		        .kia {
		          text-align: left;
		          height: 22px;
		          width: 75px;
		          color: #434343;
		          font-family: "Source Sans Pro";
		          font-size: 16px;
		          font-weight: 500;
		          letter-spacing: 0.79px;
		          line-height: 10px;
		        }
		        .hyundai {
		      		text-align: left;
		          height: 22px;
		          width: 78px;
		          color: #434343;
		          font-family: "Source Sans Pro";
		          font-size: 16px;
		          font-weight: 500;
		          letter-spacing: 0.79px;
		          line-height: 10px;
		        }
		        .skoda {
		      		text-align: left;
		          height: 22px;
		          width: 78px;
		          color: #434343;
		          font-family: "Source Sans Pro";
		          font-size: 16px;
		          font-weight: 500;
		          letter-spacing: 0.79px;
		          line-height: 10px;
		        }
		        .volkswagen {
		      		text-align: left;
		          height: 22px;
		          width: 99px;
		          color: #434343;
		          font-family: "Source Sans Pro";
		          font-size: 16px;
		          font-weight: 500;
		          letter-spacing: 0.79px;
		          line-height: 10px;
		        }
						.navbar-notgreen{
							.nav-link {
			    			color: #FFFFFF;
			    			font-family: "Source Sans Pro";
			    			font-size: 14px;
			    			padding-top: 0px;
			    			&.active {
			            height: 46px;
			            border-radius: 15px;
			            background-color: #12D070;
			            .navbar-text {
			              color: #FFFFFF;
			              font-weight: bold;
			            }
			    			}
			    		}
						}
						.navbar-green{
							.nav-link {
			    			color: #FFFFFF;
			    			font-family: "Source Sans Pro";
			    			font-size: 14px;
			    			padding-top: 10px;
			    			&.active {
			            height: 46px;
			            border-radius: 15px;
			            background-color: #D3F7AF;
			            .navbar-text {
			              color: #12D070;
			              font-weight: bold;
			            }
			    			}
			    		}
						}

		    }
			}
	}
	}
	.footer-div {
    height: 210px;
		border-radius: 43px 43px 0 0;
	}
	.side-nav {
  width: 12rem;
  background-color: #ffffff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
	a {
		line-height: 45px;
	}
	ul li {
		padding-left: 23px;
		width: 161px;
	}
	.logo-wrapper {
		width: 195px;
		img {
		  padding-top: 8%;
		  padding-right: 60px;
		  padding-bottom: 7%;
		  padding-left: 15px;
		}
	}
	}
}
