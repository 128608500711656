
.bodywrapper {
	min-height: 100vh;
	padding-top: 10.2rem;
	background-color: #ffffff;
	//background-image: url('../../media/images/shutterstock.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	//background-attachment: fixed;
}

.bodywrapper2 {
	min-height: 100vh;
	padding-top: 8.2rem;
	background-color: #ffffff;
	background: linear-gradient(to right, #E7D326 50%, #ffffff 0%);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center left;
	//background-attachment: fixed;
}

.bodywrapper3 {
	min-height: 100vh;
	padding-top: 8.2rem;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: center left;
}

.bodywrapper4 {
	min-height: 100vh;
	//padding-top: 6rem;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: center left;
}

.bodywrapper5 {
	min-height: 100vh;
	padding-top: 8.2rem;
	background-color: #ffffff;
	background: linear-gradient(to right, #ffffff 50%, #E7D326 50%);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center left;
	//background-attachment: fixed;
}

.bodywrapper6 {
	min-height: 100vh;
	padding-top: 9.2rem;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: center left;
}
.bodywrapper7 {
	min-height: 100vh;
	padding-top: 5.2rem;
	background-color: #12D070;
	background-image: url('../../media/images/Group 7.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
}
.bodywrapper8 {
	min-height: 100vh;
	padding-top: 9.2rem;
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: center left;
}

.logo {
	width: 112px;
	height: 101px;

}

@media (min-width: 410px) and (max-width: 575.98px) {

}


@media (max-width: 575.98px) {
	.bodywrapper {
		min-height: 100vh;
		padding-top: 4rem;
		background-color: #ffffff;
		background-image: none;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: top center;
		//background-attachment: fixed;
	}

	.bodywrapper2 {
		min-height: 200vh;
		padding-top: 4rem;
		background-color: #12D070;
		background: linear-gradient(to right, #12D070 50%, #12D070 0%);
		background-size: 100% 50%;
		background-repeat: no-repeat;
		background-position: top left;
		//background-attachment: fixed;
	}

	.bodywrapper3 {
		min-height: 185vh;
		padding-top: 3rem;
		background-color: #12D070;
		background-repeat: no-repeat;
		background-position: center left;
	}

	.bodywrapper4 {
		min-height: 0vh;
		padding-top: 3rem;
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-position: center left;
	}

	.bodywrapper5 {
		min-height: 0vh;
		padding-top: 3rem;
		padding-bottom: 0px;
		background-color: #ffffff;
		background: linear-gradient(to right, #ffffff 50%, #ffffff 50%);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center left;
		//background-attachment: fixed;
	}
	.bodywrapper6 {
		min-height: 93vh;
		padding-top: 0rem;
		padding-bottom: 0px;
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-position: center left;
	}
	.bodywrapper7 {
	//	min-height: 93vh;
		padding-top: 0rem;
		padding-bottom: 210px;
		background-color: #12D070;
		background-repeat: no-repeat;
		background-position: center left;
	}
	.bodywrapper8 {
		min-height: 100vh;
		padding-top: 5.2rem;
		padding-bottom: 210px;
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-position: center left;
	}

}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) and (max-width: 1499.98px) {

}
