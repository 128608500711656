.rounded {
	border-radius: 10px!important;
}

.userpage-logo {
	width: 85px;
	height: auto;
}

.userpage-b2bicon {
	width: 150px;
	height: auto;
}

.userpage-userprofileicon {
	width: 50px;
	height: auto;
}

.avatar {
	height: 2.9rem;
	width: 2.9rem;
	min-width: 2.9rem;
	display: inline-block;
	position: relative;
	border-radius: 50%;
	box-shadow: 0 6px 8px 0 rgba(16,27,79,0.2);
	img, span {
	     width: 100%;
	     height: 100%;
	     border-radius: 50%;
	     object-fit: cover;
	     display: flex;
	     align-items: center;
	     justify-content: center;
	}
	&.avatar-sm{
		height: 2.35rem;
		width: 2.35rem;
		min-width: 2.35rem;
	}
	&.avatar-card{
		height: 4rem;
		width: 4rem;
		min-width: 4rem;
	}
	&.avatar-md {
		height: 3.75rem;
		width: 3.75rem;
		min-width: 3.75rem;
	}
	&.avatar-lg {
		height: 5rem;
		width: 5rem;
		min-width: 5rem;
	}
	&.avatar-xl {
		height: 7.5rem;
		width: 7.5rem;
		min-width: 7.5rem;
	}
	&.avatar-xxl {
		height: 9.375rem;
		width: 9.375rem;
		min-width: 9.375rem;
	}

	&.avatar-primary {
	  background: #665dfe;
	  color: #fff;
	}
	&.avatar-success {
	  background: #44a675;
	  color: #fff;
	}
	&.avatar-secondary {
	  background: #6c757d;
	  color: #fff;
	}
	&.avatar-danger {
	  background: #ff337c;
	  color: #fff;
	}
	&.avatar-warning {
	  background: #ffb74d;
	  color: #fff;
	}
	&.avatar-info {
	  background: #3c91ec;
	  color: #fff;
	}
	&.avatar-away:before,
	&.avatar-busy:before,
	&.avatar-offline:before,
	&.avatar-online:before {
	  content: "";
	  position: absolute;
	  display: block;
	  width: 0.8rem;
	  height: 0.8rem;
	  border-radius: 50%;
	  top: 0;
	  right: 0;
	  border: 2px solid #fff;
	}
	&.avatar-online:before {
	  background: #62EC03;
	}
	&.avatar-offline:before {
	  background: #6c757d;
	}
	&.avatar-busy:before {
	  background: #ff337c;
	}
	&.avatar-away:before {
	  background: #ffb74d;
	}
}

.img-partner-rating-left{
	width: 40%;
}

.img-partner-rating-right{
	width: 40%;
}

.img-partner-rating-center{
	width: 100%;
}

@media (max-width: 575.98px) {
	.avatar {
		&.avatar-card{
			height: 2.9rem;
			width: 2.9rem;
			min-width: 2.9rem;
		}
	}
	.userpage-userprofileicon {
		width: 45px;
	}
	.userpage-b2bicon {
		width: 125px;
	}

	.img-partner-rating-left{
		width: 100%;
	}

	.img-partner-rating-right{
		width: 35%;
	}

	.img-partner-rating-center{
		width: 70%;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
	.img-partner-rating-left{
		width: 100%;
	}

	.img-partner-rating-right{
		width: 35%;
	}

	.img-partner-rating-center{
		width: 70%;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
