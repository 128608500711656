.offerlist-btngroup {
	.btn {
		padding-bottom: 0;
		margin-nottom: 19px;
	}
}



@media (max-width: 575.98px) {
	.offerlist-btngroup {
		.btn {
			padding: 1rem .5rem;
		}
	}
}

@media (min-width: 576px) and (max-width: 767.98px) { 
	.offerlist-btngroup {
		.btn {
			padding: 1rem 1.5rem;
		}
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.offerlist-btngroup {
		.btn {
			padding: 1rem 1.2rem;
		}
	}
}