.price-page-title {
	color: #30445B;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	margin-top: 30px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px
}
.price-page-text {
	font-size: 12px;
	text-align: center;
	margin-top: 15px;
	margin-right: 0px;
	margin-bottom: 35px;
	margin-left: 0px;
}
.price-page-haufig-text {
	font-size: 12px;
	text-align: left;
	margin-top: 10px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
}
.price-page-haufig-text-title {
	font-weight: bold;
	font-size: 15px;
	text-align: left;
	margin-top: 10px;
	margin-right: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
}

.price-page-haufig-text-title-first{
		font-weight: bold;
		font-size: 19px;
		text-align: left;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 20px;
		margin-left: 0px;
}
.price-tabs {
	height: 46px;
	width: 320px;
	border-radius: 23px;
	background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
	margin: 0 auto;
	.nav-item {
		width: 50%;
		height: 100%;
		text-align: center;
		.nav-link {
			color: #FFFFFF;
			font-family: "Source Sans Pro";
			font-size: 12px;
			text-transform: uppercase;
			padding: 14px;
			&.active {
				height: 100%;
				border-radius: 23px;
				background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%);
			}
		}
	}
}

.price-card {
	border-radius: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
	.price-card-signup-btn {
		border-radius: 20px;
		border: 1px solid #30445B;
		color: #30445B;
		text-transform: uppercase;
		margin-top: 15px;
	}
	&.final{
		max-width: 259px;

	}
	&.active {
		margin-top: 15px;
		margin-bottom: 15px;
		padding-top: 15px;
		padding-bottom: 15px;
		border: 2px solid rgba(3,96,236,0.5);
		z-index: 10;
		&.left {
			margin-right: -20px;

		}
		&.right {
			margin-left: -25px;

		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(206.57deg, #00C6FA 0%, #0360EC 100%) !important;;
			border: 0;

		}
	}
	&.left {
			margin-right: -25px;


		}
		&.right {
			margin-left: -25px;


		}

	.recommended {
		background-color: #03ECAB;
		color: #ffffff;
		text-transform: uppercase;
		font-size: 10px;
		line-height: 13px;
		padding: 2px 10px;
	}

	h1 {
		font-size: 14px;
		font-weight: 600!important;
		margin-top: 20px;
		color: #30445B;
	}

	.price {
		color: #30445B;
		.currency {
			font-size: 20px;
		}
		.value {
			font-size: 48px;
			font-weight: 600;
			padding-left: 20px;
			padding-right: 20px;
		}
		.period {
			font-size: 12px;
		}
	}

	.func-list {
		list-style: none;
		padding-left: 0;
		text-align: left;
		margin-top: 30px;
		li {
			line-height: 30px;
			color: #30445B;
			font-size: 12px;
			&:before {
				content: "";
				display: inline-block;
				width: 14px;
				height: 14px;
				margin: 3px 6px -3px 0;
				background-image: url('../../media/newicons/check-mark-button.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&.func-plus {
				font-weight: 600;
				&:before {
					background-image: url('../../media/newicons/plus-button.png');
				}
			}
		}
	}

}

.price-checkbox {
	margin-top: 12px;
	.form-check-input[type="checkbox"]+label {
		padding-left: 24px;
		font-weight: bold;
		&:before {
			//background-image: url('../../media/newicons/checkBox.png');
			background-size: cover;
			border:0;
		}
	}

	.form-check-input[type="checkbox"]:checked+label:before {
		//background-image: url('../../media/newicons/checkBoxChecked.png');
		background-size: cover;
		top: 0;
		left: 0;
		width: 18px;
		height: 18px;
		transform: none;
	}
}

.companies-ib2b-newcompany {
    margin-bottom: 20px;
		margin-top: 90px;
}

@media (max-width: 575.98px) {
	.price-card {
		&.left {
			margin-right: 0px;
		}
		&.right {
			margin-left: 0px;
		}

		&.active {
			border: 0;
			&.left {
				margin-right: 0px;
			}
			&.right {
				margin-left: 0px;
			}
		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
			border: 0;

		}
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.price-card {
		&.left {
			margin-right: 0px;
		}
		&.right {
			margin-left: 0px;
		}

		&.active {
			border: 0;
			&.left {
				margin-right: 0px;
			}
			&.right {
				margin-left: 0px;
			}
		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
			border: 0;

		}
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.price-card {
		&.left {
			margin-right: 0px;
		}
		&.right {
			margin-left: 0px;
		}

		&.active {
			border: 0;
			&.left {
				margin-right: 0px;
			}
			&.right {
				margin-left: 0px;
			}
		}

		.price-card-signup-btn {
			color: #ffffff;
			background: linear-gradient(90deg, #B7B7B7 0%, #DDDDDD 100%);
			border: 0;

		}
	}
}
