.date-form {
  .form-outline .form-control {
    min-height: 34px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 0;
    border: 0;
    background: transparent;
    transition: all .2s linear;
    font-size: .8rem;
  }
  .datepicker-toggle-button {
    height: 34px;
    width: 39px;
    background: #EAEAEA;
    border: 1px solid #C5C5C5;
    border-radius:0 5px 5px 0;
    position: absolute;
    padding-top: 3px;
    top: 17px;
    right: -19px;
    z-index: 0;
    cursor: pointer;
    :hover {
      color: #E7D326!important;
    }
    :active {
      color: #E7D326!important;
    }
  }
  .fa-2x {
    font-size: 1.5em;
  }

}
.time-form {
  .form-outline .form-control {
    min-height: 34px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 0;
    border: 0;
    background: transparent;
    transition: all .2s linear;
    font-size: .8rem;
  }
  .timepicker-toggle-button {
    height: 34px;
    width: 39px;
    background: #EAEAEA;
    border: 1px solid #C5C5C5;
    border-radius:0 5px 5px 0;
    position: absolute;
    padding-top: 3px;
    top: 17px;
    right: -19px;
    z-index: 0;
    cursor: pointer;
    :hover {
      color: #E7D326;
    }
    :active {
      color: #E7D326!important;
    }
  }
  .fa-2x {
    font-size: 1.5em;
  }
}
.datepicker-cell.selected .datepicker-cell-content {
  background-color: #E7D326!important;
}
.datepicker-toggle-button:hover {
  color: #E7D326!important;
}
.datepicker-toggle-button:active {
  color: #E7D326!important;
}
.datepicker-toggle-button:focus {
  color: #E7D326!important;
}
.timepicker-toggle-button:hover {
  color: #E7D326!important;
}
.timepicker-toggle-button:active {
  color: #E7D326!important;
}
.timepicker-toggle-button:focus {
  color: #E7D326!important;
}
.timepicker-head {
  background-color: #E7D326!important;
}
.timepicker-hand-pointer {
  background-color: #E7D326!important;
}
.timepicker-middle-dot {
  background-color: #E7D326!important;
}
.timepicker-time-tips-minutes.active, .timepicker-time-tips-inner.active, .timepicker-time-tips-hours.active {
  background-color: #E7D326!important;
}
.timepicker-circle {
  border: 14px solid #E7D326!important;
  background-color: #E7D326!important;
}
.timepicker-button {
  color: #E7D326!important;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {

}
