.companies-title {
	font-size: 16px;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 20px;
}

.companies-ib2b {
	margin-bottom: 20px;
}

.companies-search-btn {
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;
}

#basicCollapse-profile {
	margin-top: -1.2rem;
	/*&.collapse {
	  &:not(.show) {
	    display: none;
	  }
	}
	&.collapsing {
	  overflow: hidden;
	  transition: height 3.35s ease ;
	}
	&.fade {
	  transition: opacity 5.35s linear;

	  &:not(.show) {
	    opacity: 0;
	  }
	}*/
}
